<template>
  <v-dialog
    v-model="dialog"
    :max-width="$dialog.content"
    scrollable
  >
    <close-btn @click="closeDialog()" depressed overflow />

    <v-card>
      <v-card-title>
        <h5 class="font-weight-bold text-h6">{{ term.title }}</h5>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5">
        <div v-html="term.content"></div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import CloseBtn from '../../../components/CloseBtn.vue'
export default {
  components: { CloseBtn },
  props: {
    id: String
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      dialog: false,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    term: function () {
      return this.$store.getters['order_term/term'](this.id)
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    closeDialog() {
      this.dialog = false
    }
  }
}
</script>