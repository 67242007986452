<template>
  <v-dialog
    v-model="dialog"
    :max-width="$dialog.medium"
    scrollable
  >
    <close-btn @click="closeDialog()"  depressed overflow />

    <v-card>
      <v-card-title>
        <h5 class="font-weight-bold text-h5">Create New Term</h5>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-text-field
            v-model="data.title"
            :rules="[rules.required]"
            label="Title"
            outlined
            dense
          ></v-text-field>

          <wysiwyg :rules="[rules.required]" v-model="data.content" />

          <v-text-field
            v-model="data.content"
            :rules="[rules.required]"
            :style="{ border: 'none !important' }"
            type="hidden"
            class="pt-0"
            height="0"
            rounded
          ></v-text-field>
        </v-form>

        <div class="mt-3">
          <v-btn
            @click="validateForm()"
            :loading="status.adding"
            color="primary"
            small
          >Save Term</v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import rules from '@/rules'

export default {
  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      dialog: false,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      data: state => state.order_term.data,
      status: state => state.order_term.status,
    })
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('order_term', [
      'createOrderTerm',
    ]),

    closeDialog() {
      this.dialog = false
      this.$store.commit('order_term/setData', {})
      this.$refs.form.reset()
    },

    validateForm() {
      if (this.$refs.form.validate()) {
        Promise.resolve(this.createOrderTerm())
        .then(() => {
          this.closeDialog()
        })
      }
    },
  },
}
</script>