<template>
  <div class="mt-5">
    <div class="d-flex align-center py-3">
      <v-sheet width="100%" max-width="450">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          class="custom-shadow"
          placeholder="Search"
          hide-details
          clearable
          outlined
          rounded
          dense
        ></v-text-field>
      </v-sheet>

      <v-spacer></v-spacer>

      <v-btn 
        @click="showCreateDialog()"
        class="box-shadow"
        color="primary"
        small
        text 
      >Create New Term</v-btn>
    </div>

    <v-card class="box-shadow" outlined>
      <preloader v-if="status.getting" />

      <v-card-text v-if="!status.getting && !terms.length">
        <v-alert @click="showCreateDialog()" type="info" border="left" class="mb-0" text>
          <a href="@" @click.prevent="showCreateDialog()" class="text-decoration-none info--text">Create your first Term.</a>
        </v-alert>
      </v-card-text>

      <v-simple-table v-if="!status.getting && terms.length">
        <template #default>
          <thead>
            <tr>
              <th>Title</th>
              <th>Created by</th>
              <th>Last updated by</th>
              <th class="text-center">Published</th>
              <th width="15"></th>
            </tr>
          </thead>
          <v-fade-transition tag="tbody" group>
            <tr v-for="term in confTerms" :key="term.id">
              <td class="font-weight-bold">
                <a @click="showPreview(term.id)" class="primary--text" href.prevent="#">{{ term.title }}</a>
              </td> 
              <td class="caption">
                <span>{{ $store.getters['users/user'](term.user).fullName }}</span>
                &nbsp;<span class="grey--text">last {{ term.created | formatDate }}</span>
              </td>
              <td class="caption">
                <span>{{ term.last_updated_by ? $store.getters['users/user'](term.last_updated_by).fullName : $store.getters['users/user'](term.user).fullName }}</span>
                &nbsp;<span class="grey--text">last {{ term.updated ? term.updated : term.created | formatDate }}</span>
              </td>
              <td class="text-center">
                <v-icon :color="term.published ? 'success' : 'grey lighten-1'" >mdi-checkbox-marked-circle-outline</v-icon>
              </td>
              <td class="text-right">
                <v-menu bottom left offset-y>
                  <template #activator="{ on }">
                    <v-btn
                      v-on="on"
                      icon
                    >
                      <v-icon>mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item @click="editTerm(term)">
                      <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="showPreview(term.id)">
                      <v-list-item-title>Preview</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="!term.published" @click="updatePublishStatus({ term, status: true })">
                      <v-list-item-title>Publish</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="term.published" @click="updatePublishStatus({ term, status: false })">
                      <v-list-item-title>Unpublish</v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item @click="showDeleteDialog(term)">
                      <v-list-item-title class="error--text">Delete</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </v-fade-transition>
        </template>
      </v-simple-table>
    </v-card>

    <!-- CREATE FORM -->
    <create-form ref="dialog" />

    <!-- TERM PREVIEW -->
    <term-preview ref="previewDialog" :id="preview" />

    <!-- DELETE DIALOG -->
    <confirm-delete
      :show="deleteDialog"
      :message="deleteMessage"
      :deleting="status.deleting"
      @cancel="closeDeleteDialog()"
      @confirmed="deleteConfirmed()"
      textConfirmation="Term"
      captcha
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import CreateForm from './components/CreateTermDialog'
import TermPreview from './components/PreviewTermDialog'

export default {
  metaInfo: {
    title: 'Order Terms'
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      search: null,
      preview: null,
      toDelete: null,
      deleteDialog: false,
      deleteMessage: null,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      terms: state => state.order_term.terms,
      status: state => state.order_term.status,
    }),

    confTerms: function () {
      return this.terms.filter(term => {
        if (this.search) {
          return term.title.toLowerCase().includes(this.search.toLowerCase())
        }
        else return true
      })
    }
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    CreateForm,
    TermPreview,
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('order_term', [
      'updatePublishStatus',
      'deleteTerm',
      'getTerms',
    ]),

    showCreateDialog() {
      this.$refs.dialog.dialog = true
    },

    editTerm(term) {
      this.$store.commit('order_term/setData', term)
      this.showCreateDialog()
    },

    showPreview(id) {
      this.preview = id
      this.$refs.previewDialog.dialog = true
    },

    showDeleteDialog(term) {
      this.toDelete = term
      this.deleteMessage = `Delete ${term.title}?`
      this.deleteDialog = true
    },

    closeDeleteDialog() {
      this.toDelete = null
      this.deleteMessage = null
      this.deleteDialog = false
    },

    deleteConfirmed() {
      Promise.resolve(this.deleteTerm(this.toDelete))
      .then(() => {
        this.closeDeleteDialog()
      })
    }
  },
  
  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    if (!this.status.firstLoad) this.getTerms()
  }
}
</script>